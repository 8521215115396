import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.min";
import "../../node_modules/youtube-background/jquery.youtube-background.js";
import "../../node_modules/jquery-custom-select/dist/js/jquery.custom-select.min";
import "../../node_modules/inputmask/dist/jquery.inputmask.min.js";
import Swiper from '../../node_modules/swiper/swiper-bundle.min';
import imagesLoaded from '../../node_modules/imagesloaded/imagesloaded.pkgd.min.js';
import alertify from '../../node_modules/alertifyjs/build/alertify.min';
import gsap from '../../node_modules/gsap/dist/gsap.min.js';
import "./lib/flowtype";
import "./lib/jquery.rut.min";
var $ = require('jquery');
imagesLoaded.makeJQueryPlugin( $ );

(function ($, root, undefined) {
"use strict";
$(function () {

var PathUrl = $("body").attr("data-path"),
SiteUrl = $("body").attr("data-url");
var WindowHeight = function(){
$('.get_height').css({'min-height':  window.innerHeight+ 'px'});
$('.get_width').css({'min-width':  window.innerWidth+ 'px'});
$('.get_mid_height').css({'min-height':  window.innerHeight/1.3+ 'px'});
}

WindowHeight();

$('.apis-wrapper').imagesLoaded()
.always( function( instance ) {
    setTimeout(function() { 
        $(".apis-preload").fadeOut("fast");
        $("html, body").css("overflow","visible");
    }, 300);
});

var shrinkHeader = 100;
$(window).scroll(function() {
var scroll = getCurrentScroll();
if ( scroll >= shrinkHeader ) {
$('.header-apis').addClass('active');
$('.header-mobile').addClass('active');
}
else {
$('.header-apis').removeClass('active');
$('.header-mobile').removeClass('active');
}
});
function getCurrentScroll() {
return window.scrollY;
}

jQuery('[data-vbg]').youtube_background({
    'load-background' : PathUrl+'dist/img/bg-video.png'
});
const videoBackgrounds = VIDEO_BACKGROUNDS;
const firstElement = document.querySelector('[data-vbg]');
const firstInstance = videoBackgrounds.get(firstElement);

firstElement.addEventListener('video-background-ready', function(event) {
    setTimeout(function() { 
        $(".apis-img-cover").fadeOut("fast");
    }, 1500);
})

alertify.dialog('alert').set({maximizable: false, resizable:false, movable:false, title:"Apis Capacitaciones alerta :",transition:"zoom",closable:false});
alertify.dialog('confirm').set({maximizable: false, resizable:false, movable:false, title:"Apis Capacitaciones confirmación :",transition:"zoom",closable:false});
alertify.set('notifier','position', 'bottom-right');
alertify.set('notifier','delay',5);


const MenuMobile = new bootstrap.Offcanvas('#MobileMenu');
const MenuMobileAct = document.getElementById('MobileMenu')
MenuMobileAct.addEventListener('hide.bs.offcanvas', event => {
    $('body').removeClass('menu-open');
});

$('.bt-menu').on('click', function(e) {
    e.preventDefault();
    $('body').toggleClass('menu-open');
    if($('body').hasClass('menu-open')){
        MenuMobile.show();
    }else{
        MenuMobile.hide();
    }
});

$('.apis-cover-info h1').flowtype({
fontRatio : 12,
minFont : 35,
});

$('#nav-body .icon-item h2').flowtype({
fontRatio : 11,
minFont : 20,
});

$('.title-main').flowtype({
fontRatio : 25,
minFont : 30,
});

$('.sub-title-main').flowtype({
fontRatio : 40,
minFont : 23,
});

$('.title-news').flowtype({
fontRatio : 13,
minFont : 25,
});

$('.sub-title-news').flowtype({
fontRatio : 50,
minFont : 14,
});

$('select').customSelect({
includeValue: false
});


var logos = new Swiper(".trusted", {
loop:true,
speed:7000,
allowTouchMove: false,
disableOnInteraction: false,
autoplay: {
delay: 1,
},
breakpoints: {
320: {
slidesPerView: 2,
spaceBetween: 10,
},
480: {
slidesPerView: 2,
spaceBetween: 20,
},
768: {
slidesPerView: 4,
spaceBetween: 20,
},
1024: {
slidesPerView: 7,
spaceBetween: 20,
},
1140: {
slidesPerView: 8,
spaceBetween: 20,
},
},
});

var swiper_ticket = new Swiper(".curso-slide", {
slidesPerView: 3,
spaceBetween: 50,
breakpoints: {
320: {
    slidesPerView: 1,
},
480: {
    slidesPerView: 1,
},
768: {
    slidesPerView: 2,
},
1024: {
    slidesPerView: 3,
},
1140: {
    slidesPerView: 3,
},
1280: {
    slidesPerView: 3,
},
},
});

$(".slide-left").on("click",function(){
    swiper_ticket.slidePrev();
});

$(".slide-right").on("click",function(){
    swiper_ticket.slideNext();
});

swiper_ticket.on('reachEnd', function(){
$(".slide-left").removeClass("disabled").addClass("active");
$(".slide-right").addClass("disabled").removeClass("active");
});

swiper_ticket.on('reachBeginning', function(){
$(".slide-left").addClass("disabled").removeClass("active");
$(".slide-right").removeClass("disabled").addClass("active");
});


$(".apis-search-btn").on("click",function(e){
e.preventDefault;
if($(".select-curso option:selected").val() == "0") {  
    alertify.alert("Selecciona un curso.").set('label', 'Aceptar');   
    return false;  
}
if($(".select-region option:selected").val() == "0") {  
    alertify.alert("Selecciona una región.").set('label', 'Aceptar');   
    return false;  
}    
$(".apis-search-btn").prop('disabled', true);
var action = 'BuscarCursoHome',
curso = $(".select-curso option:selected" ).val(),
region = $(".select-region option:selected" ).val(),
data = {action:action, curso:curso, region:region};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to(".search-preload", {opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
    if(response.status == "success"){
        window.location.href = SiteUrl+"/cursos/";
    }
}
});
});

const offcanvasCart = new bootstrap.Offcanvas('#offcanvasCart');

$("body").on("click",".btn-addtocart",function(e){
e.preventDefault();
$(this).prop('disabled', true);
let action = 'AddToCart',
curso = $(this).attr("data-curso"),
data = {action:action, curso:curso};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($("#curso-"+curso).find(".curso-preload"), {opacity:1, autoAlpha:1, duration: 0.5});
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
if(response.status == "incart"){
offcanvasCart.show();
alertify.alert("El curso ya se encuentra en el carrito.").set('label', 'Aceptar');  
gsap.to($("#curso-"+curso).find(".curso-preload"), {opacity:0, autoAlpha:0, duration: 0.5}); 
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
$(".btn-addtocart").prop('disabled', false);
return false; 
}
else if(response.status == "success"){
$(".CartCounter").html(response.count);
$(".total-compra").find("strong").html(response.total);
gsap.to($("#curso-"+curso).find(".curso-preload"), {opacity:0, autoAlpha:0, duration: 0.5});
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
$(".btn-addtocart").prop('disabled', false);
$("#offcanvasCart").find(".main-cart").empty();
$.when(
$("#offcanvasCart").find(".main-cart").append(response.data),
gsap.set($("#offcanvasCart .main-cart").find(".item-cart").last(), {x:"150%",opacity:0, autoAlpha:0, duration: 0.5}),
).then(
offcanvasCart.show(),
gsap.to($("#offcanvasCart .main-cart").find(".item-cart").last(), {x:"0%",opacity:1, autoAlpha:1, duration: 0.5}),
);
}
}
});
});

$("body").on("click",".eliminar-curso",function(e){
e.preventDefault();
let action = 'DeleteCurso',
curso = $(this).attr("data-curso"),
data = {action:action, curso:curso};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($("#item-curso-"+curso).find(".curso-preload"), {opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
if(response.status == "success"){
if(response.count == "0"){
    offcanvasCart.hide();
    $("#offcanvasCart").find(".main-cart").html('<li class="text-center item-cart"><h4 class="no-cursos m-0 py-3">No has agregado ningún curso al carrito.</h4></li>');
}
$(".CartCounter").html(response.count);
$(".total-compra").find("strong").html(response.total);
gsap.to($("#item-curso-"+curso), {x:"-150%", duration: 0.6, onComplete:function(){
    $("#item-curso-"+curso).remove();
}});
}
}
});
});

$("body").on("click",".EmptyCart",function(e){
e.preventDefault();
let action = 'EmptyCart',
data = {action:action};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
if(response.status == "empty"){
alertify.alert("El carrito se encuentra vacío.").set('label', 'Aceptar');
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5}); 
offcanvasCart.hide();
return false();
}else{
alertify.confirm('¿Estás seguro que deseas vaciar el carrito?', function(){ 
$(".CartCounter").html("0");
$(".total-compra").find("strong").html("$0");
$(".main-cart").empty();
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
$("#offcanvasCart").find(".main-cart").html('<li class="text-center item-cart"><h4 class="no-cursos m-0 py-3">No has agregado ningún curso al carrito.</h4></li>');
offcanvasCart.hide();
}, function(){ 
}).set('labels', {ok:'Aceptar', cancel:'Cancelar'});
}
}
});
});

$(".close-cart").on("click",function(e){
e.preventDefault();
offcanvasCart.hide();
});

const GoPay = new bootstrap.Modal('#FinalizarCompra', {
keyboard: false,
backdrop:'static',
focus: false
});

$("#toggle").change(function() {
    if($(this).is(":checked")) {
        $(".login-data").fadeIn("fast");
    } else {
        $(".login-data").fadeOut("fast");
        $("#UsuarioRut").val("");
        $("#UsuarioContrasena").val("");
        $("#UsuarioRepContrasena").val("");
    }
});

$("#UsuarioTelefono").inputmask({ mask: "+56\\9\\ 9999-9999", greedy: false});

$("#UsuarioEmail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

$("#UsuarioRut").rut({
	formatOn: 'keyup',
    minimumLength: 8,
	validateOn: 'change keyup'
});

$(".ShowPass").on("click",function(e){
e.preventDefault();
let inputpass = $(this).attr("data-to");
$(this).toggleClass("active");
if($(this).hasClass("active")){
    $("#"+inputpass).attr("type", "text");
    $(this).find(".material-symbols-outlined").html("visibility_off");
}else{
    $("#"+inputpass).attr("type", "password");
    $(this).find(".material-symbols-outlined").html("visibility");
}
})

$(".GotoPay").on("click",function(e){
e.preventDefault();
let action = 'GoPay',
data = {action:action};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
if(response.status == "empty"){
    alertify.alert("El carrito se encuentra vacío.").set('label', 'Aceptar');
    gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5}); 
    offcanvasCart.hide();
    return false();
}else{
    gsap.to($("#offcanvasCart").find(".offcanvas-preload"),{opacity:0, autoAlpha:0, duration: 0.5}); 
    offcanvasCart.hide();
    $.when(
    $("#FinalizarCompra").find(".list-prod").empty(),
    $("#FinalizarCompra").find(".list-prod").append(response.data),
    $(".title-total-table").find(".table-monto").html(response.total),
    ).then(
    GoPay.show(),
    );
}
}
});
});

const FinalizarCompra = document.getElementById('FinalizarCompra')
FinalizarCompra.addEventListener('hidden.bs.modal', event => {
    $("#UsuarioNombre").val("");
    $("#UsuarioApellido").val("");
    $("#UsuarioEmail").val("");
    $("#UsuarioTelefono").val("");
    $("#UsuarioRut").val("");
    $("#UsuarioContrasena").val("").attr("type","password");
    $("#UsuarioRepContrasena").val("").attr("type","password");
    $(".login-data").hide();
    $("#toggle").prop("checked",false);
    $(".ShowPass").removeClass("active");
    $(".ShowPass").find(".material-symbols-outlined").html("visibility");
});

$("#PayButton").on("click",function(e){
e.preventDefault();
if($("#UsuarioNombre").val().length < 4) {  
    alertify.alert("Ingresa tu nombre.").set('label', 'Aceptar');  
    return false;  
}
if($("#UsuarioApellido").val().length < 4) {  
    alertify.alert("Ingresa tu apellido.").set('label', 'Aceptar');  
    return false;  
}
if($("#UsuarioApellido").val().length < 4) {  
    alertify.alert("Ingresa tu apellido.").set('label', 'Aceptar');  
    return false;  
}
if($("#UsuarioEmail").val().length < 1) {  
    alertify.alert("Ingresa tu email.").set('label', 'Aceptar');   
    return false;  
}  
if($("#UsuarioEmail").val().indexOf('@', 0) == -1 || $("#UsuarioEmail").val().indexOf('.', 0) == -1) {  
    alertify.alert("El email ingresado contiene errores.").set('label', 'Aceptar');  
    return false;  
}
if($("#UsuarioTelefono").val().length < 4) {  
    alertify.alert("Ingresa tu teléfono.").set('label', 'Aceptar');  
    return false;  
}
if($("#UsuarioTelefono").val().replace(/[^0-9]/g,"").length < 11) { 
    alertify.alert("Ingresa un teléfono valido.").set('label', 'Aceptar');  
    return false;   
} 

if($(".login-data").is(":visible")){

    if($("#UsuarioRut").val().length < 4) {  
        alertify.alert("Ingresa tu rut.").set('label', 'Aceptar');  
        return false;  
    }
    if(!$.validateRut($("#UsuarioRut").val())) {  
        alertify.alert("El rut ingresado no es valido.").set('label', 'Aceptar');  
        return false;  
    }

    if($("#UsuarioContrasena").val().length < 6) {  
        alertify.alert("Ingresa una contraseña (Mínimo 6 caracteres).").set('label', 'Aceptar');  
        return false;  
    }

    let pattern = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!]).*$/;
    if(!pattern.test($("#UsuarioContrasena").val())){
        alertify.alert("Tu contraseña debe contener : <br> - <span class='fw-normal'>Al menos 6 carácteres</span> <br> - <span class='fw-normal'>al menos 1 numero</span> <br> -  <span class='fw-normal'>al menos 1 carácter en minúscula (a-z)</span> <br> - <span class='fw-normal'>al menos 1 carácter en mayúscula (A-Z)</span> <br> - <span class='fw-normal'>al menos 1 carácter especial (@#$%&!)</span>").set('label', 'Aceptar');  
        return false; 
    };

    if($("#UsuarioRepContrasena").val().length < 6) {  
        alertify.alert("Confirma tu contraseña.").set('label', 'Aceptar');  
        return false;  
    }

    if ($("#UsuarioContrasena").val() != $("#UsuarioRepContrasena").val() || $("#UsuarioRepContrasena").val() != $("#UsuarioContrasena").val()){
        alertify.alert("Ambas contraseñas deben ser idénticas.").set('label', 'Aceptar');  
        return false;  
    }

}

alertify.confirm('¿Estás seguro de deseas ir a pagar tus cursos?', function(){ 
$("#PayButton").prop('disabled', true);
$("#CancelButton").prop('disabled', true);
let action = 'CheckOut',
UserName = $("#UsuarioNombre").val(),
UserLastName = $("#UsuarioApellido").val(),
UserPhone = $("#UsuarioTelefono").val(),
UserEmail = $("#UsuarioEmail").val(),
UserRut = $("#UsuarioRut").val(),
UserPassword = $("#UsuarioContrasena").val(),
data = {action:action, UserName:UserName, UserLastName:UserLastName, UserPhone:UserPhone, UserEmail:UserEmail, UserRut:UserRut, UserPassword:UserPassword};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($("#FinalizarCompra").find(".modal-preload"),{opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {

if(response.status == "GoTransbank"){
setTimeout(function() { 
    window.location.replace(response.message); 
}, 1000);
}else if(response.status == "ErrorPago"){
    alertify.alert("Error al realizar el pago.").set('label', 'Aceptar');  
    return false;
}else if(response.status == "ErrorUsuario"){
    alertify.alert("Error al registrar usuario.").set('label', 'Aceptar');  
    return false;
}

}
});
}, function(){ 
}).set('labels', {ok:'Aceptar', cancel:'Cancelar'});
});

$("#SusEmail").inputmask({
mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
greedy: false,
onBeforePaste: function (pastedValue, opts) {
pastedValue = pastedValue.toLowerCase();
return pastedValue.replace("mailto:", "");
},
definitions: {
'*': {
validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
casing: "lower"
}
}
});

$(".BtnSusc").on("click",function(e){
if($("#SusEmail").val().length < 1) {  
    alertify.alert("Ingresa tu email.").set('label', 'Aceptar');   
    return false;  
}  
if($("#SusEmail").val().indexOf('@', 0) == -1 || $("#SusEmail").val().indexOf('.', 0) == -1) {  
    alertify.alert("El email ingresado contiene errores.").set('label', 'Aceptar');  
    return false;  
}
$(".BtnSusc").prop('disabled', true);
let action = 'SendNewsLetter',
UserEmail = $("#SusEmail").val(),
data = {action:action, UserEmail:UserEmail};
$.ajax({
data:  data,
url:   SiteUrl+'/json',
type:  'post',
cache: false,
beforeSend: function() {
gsap.to($(".newsletter-preload"),{opacity:1, autoAlpha:1, duration: 0.5});
},
success:function(response) {
if(response.message == "exist"){
gsap.to($(".newsletter-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
alertify.alert("Tu email ya se encuentra suscrito a nuestro newsletter.").set('label', 'Aceptar');  
$("#SusEmail").val("");
$(".BtnSusc").prop('disabled', false);
}else if(response.message == "success"){
gsap.to($(".newsletter-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
alertify.alert("Te has suscrito a nuestro newsletter con éxito.").set('label', 'Aceptar'); 
$("#SusEmail").val(""); 
$(".BtnSusc").prop('disabled', false);
}else{
gsap.to($(".newsletter-preload"),{opacity:0, autoAlpha:0, duration: 0.5});
alertify.alert("Error al suscribirse.").set('label', 'Aceptar');  
$("#SusEmail").val("");
$(".BtnSusc").prop('disabled', false);
return false;
}

}
});
});

$(window).on('resize', function(){
WindowHeight();
});

$(window).on('load', function(){
WindowHeight();
window.scrollTo(0,0);
});

window.onbeforeunload = function () {
$(".apis-preload").fadeIn("fast",function(){
window.scrollTo(0, 0);
});
};

});
})(jQuery, this);
